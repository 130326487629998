import { IonButton, IonFooter, IonToolbar } from '@ionic/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

type RequestNegotiationFooterProps = {
  isFormValid: boolean | undefined;
  onClick: Dispatch<SetStateAction<boolean>>;
};

const RequestNegotiationFooter = ({ isFormValid, onClick }: RequestNegotiationFooterProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });

  return (
    <IonFooter>
      <IonToolbar color="light">
        <div slot="primary" className="stack ion-padding">
          <IonButton fill="outline" expand="block" onClick={() => onClick(false)}>
            {t('actions.cancel')}
          </IonButton>

          <IonButton
            expand="block"
            type="submit"
            form="remunerationNegotiationForm"
            disabled={!isFormValid}
          >
            {t('negotiateRemuneration.actions.negotiateRemuneration')}
          </IonButton>
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default RequestNegotiationFooter;
