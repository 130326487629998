import { useMutation, useQueryClient } from '@tanstack/react-query';

import userAccountQueries from '@carers/utils/AccountQueryFactory';
import { CustomAxiosRequestConfig, httpService } from '@shared/httpService';

type UserUpdateInformation = {
  siretId: string;
};

const putProfessionalInformation = async (info: UserUpdateInformation) => {
  const url = `/update-professional-information`;
  const config: CustomAxiosRequestConfig = {
    validationErrorMessage: 'profile.messages.errors.invalidSiret',
  };

  return httpService.put<void>(url, info, config).then((response) => response.data);
};

const useUpdateProfessionalInformation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: putProfessionalInformation,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(userAccountQueries.detail().queryKey, (currentValue) =>
        currentValue ? { ...currentValue, siretId: variables.siretId } : undefined,
      );
    },
  });
};

export { useUpdateProfessionalInformation, type UserUpdateInformation };
