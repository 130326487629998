import { Dispatch, SetStateAction, useState } from 'react';

import { Request } from '@carers/views/Requests/models/Requests';

type CarerHourlyPrices = {
  carerBusinessDayHourlyPrice: number | null;
  setCarerBusinessDayHourlyPrice: Dispatch<SetStateAction<number | null>>;
  carerNightHourlyPrice: number | null;
  setCarerNightHourlyPrice: Dispatch<SetStateAction<number | null>>;
  carerSundayHourlyPrice: number | null;
  setCarerSundayHourlyPrice: Dispatch<SetStateAction<number | null>>;
  carerBankHolidayHourlyPrice: number | null;
  setCarerBankHolidayHourlyPrice: Dispatch<SetStateAction<number | null>>;
};

const useCarerHourlyPrices = (request: Request): CarerHourlyPrices => {
  const { hourlyPrices: requestHourlyPrices } = request;

  const [carerBusinessDayHourlyPrice, setCarerBusinessDayHourlyPrice] = useState(
    requestHourlyPrices?.businessDayHourlyPrice
      ? requestHourlyPrices.businessDayHourlyPrice / 100
      : null,
  );
  const [carerNightHourlyPrice, setCarerNightHourlyPrice] = useState(
    requestHourlyPrices?.nightHourlyPrice ? requestHourlyPrices.nightHourlyPrice / 100 : null,
  );
  const [carerSundayHourlyPrice, setCarerSundayHourlyPrice] = useState(
    requestHourlyPrices?.sundayHourlyPrice ? requestHourlyPrices.sundayHourlyPrice / 100 : null,
  );
  const [carerBankHolidayHourlyPrice, setCarerBankHolidayHourlyPrice] = useState(
    requestHourlyPrices?.bankHolidayHourlyPrice
      ? requestHourlyPrices.bankHolidayHourlyPrice / 100
      : null,
  );

  return {
    carerBusinessDayHourlyPrice,
    setCarerBusinessDayHourlyPrice,
    carerNightHourlyPrice,
    setCarerNightHourlyPrice,
    carerSundayHourlyPrice,
    setCarerSundayHourlyPrice,
    carerBankHolidayHourlyPrice,
    setCarerBankHolidayHourlyPrice,
  };
};

export default useCarerHourlyPrices;
export type { CarerHourlyPrices };
