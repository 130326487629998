import { GenericAbortSignal } from 'axios';

import { httpService } from '@shared/httpService';
import { LabelValue } from '@shared/models/LabelValue';

type UserAccountDTO = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  frontAppChatbotUserHash: string;
  phone: string;
  siretId: string | null;
  punctualAvailabilitiesFormUrl: string;
  acceptedJobs: LabelValue<string>[];
  hasPendingUnsubscription: boolean;
  doesNotAcceptIndependentJobs: boolean;
};

type UserAccount = {
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  frontAppChatbotUserHash: string;
  acceptedJobs: LabelValue<string>[];
  siretId: string | null;
  punctualAvailabilitiesFormUrl: string;
  hasPendingUnsubscription: boolean;
  doesNotAcceptIndependentJobs: boolean;
};

const mapUserAccountDTOToUserAccount = (dto: UserAccountDTO): UserAccount => {
  return {
    firstName: dto.firstName,
    lastName: dto.lastName,
    fullName: `${dto.firstName} ${dto.lastName}`,
    email: dto.email,
    frontAppChatbotUserHash: dto.frontAppChatbotUserHash,
    siretId: dto.siretId,
    punctualAvailabilitiesFormUrl: dto.punctualAvailabilitiesFormUrl,
    acceptedJobs: dto.acceptedJobs,
    hasPendingUnsubscription: dto.hasPendingUnsubscription,
    doesNotAcceptIndependentJobs: dto.doesNotAcceptIndependentJobs,
  };
};

const fetchUserAccount = async (signal: GenericAbortSignal) => {
  const url = `/account`;

  return httpService
    .get<UserAccountDTO>(url, { signal })
    .then((response) => mapUserAccountDTOToUserAccount(response.data));
};

export default fetchUserAccount;

export type { UserAccount };
