import { IonCheckbox, IonInput, IonText, IonTextarea } from '@ionic/react';
import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CarerHourlyPrices } from '@carers/views/Requests/hooks/useCarerHourlyPrices';
import { Request } from '@carers/views/Requests/models/Requests';
import styles from '@carers/views/Requests/RequestDetail/RequestNegotiation/RequestNegotiation.module.css';

type RequestNegotiationFormProps = {
  request: Request;
  step: string;
  setCheckboxChecked: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (event?: FormEvent<HTMLFormElement>) => Promise<void>;
  carerHourlyPrices: CarerHourlyPrices;
  explanation: string;
  setExplanation: Dispatch<SetStateAction<string>>;
  isExplanationValid: boolean | undefined;
  setIsExplanationValid: Dispatch<SetStateAction<boolean | undefined>>;
};

const RequestNegotiationForm = ({
  request,
  step,
  setCheckboxChecked: setCheckboxCheck,
  handleSubmit,
  carerHourlyPrices,
  explanation,
  setExplanation,
  isExplanationValid,
  setIsExplanationValid,
}: RequestNegotiationFormProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });

  const {
    carerBusinessDayHourlyPrice,
    setCarerBusinessDayHourlyPrice,
    carerNightHourlyPrice,
    setCarerNightHourlyPrice,
    carerSundayHourlyPrice,
    setCarerSundayHourlyPrice,
    carerBankHolidayHourlyPrice,
    setCarerBankHolidayHourlyPrice,
  } = carerHourlyPrices;

  const [isExplanationTouched, setIsExplanationTouched] = useState(false);

  const validateExplanation = (carerExplanation: string) => {
    return carerExplanation.length > 10;
  };

  const validate = (ev: Event) => {
    const { value } = ev.target as HTMLTextAreaElement;

    setIsExplanationValid(validateExplanation(value));

    setExplanation(value);
  };

  const markTouched = () => {
    setIsExplanationTouched(true);
  };

  return (
    <form
      onSubmit={handleSubmit}
      id="remunerationNegotiationForm"
      className="ion-padding-top stack"
    >
      {request.hourlyPrices?.businessDayHourlyPrice && (
        <IonInput
          type="number"
          inputMode="numeric"
          value={carerBusinessDayHourlyPrice}
          onIonInput={(e) => setCarerBusinessDayHourlyPrice(e.target.value as number)}
          min={request.hourlyPrices.businessDayHourlyPrice / 100}
          step={step}
          label={t('businessDays')}
          labelPlacement="floating"
          fill="outline"
          required
          helperText={`${t('currentRate')} : ${
            request.hourlyPrices.businessDayHourlyPrice / 100
          } ${t('currencyPerHour')}`}
        >
          <IonText slot="end">{t('currencyPerHour')}</IonText>
        </IonInput>
      )}
      {!!request.hourlyPrices?.nightHourlyPrice && (
        <IonInput
          type="number"
          inputMode="numeric"
          value={carerNightHourlyPrice}
          onIonInput={(e) => setCarerNightHourlyPrice(e.target.value as number)}
          min={request.hourlyPrices.nightHourlyPrice / 100}
          step={step}
          label={t('nights')}
          labelPlacement="floating"
          fill="outline"
          required
          helperText={`${t('currentRate')} : ${request.hourlyPrices.nightHourlyPrice / 100} ${t(
            'currencyPerHour',
          )}`}
        >
          <IonText slot="end">{t('currencyPerHour')}</IonText>
        </IonInput>
      )}
      {!!request.hourlyPrices?.sundayHourlyPrice && (
        <IonInput
          type="number"
          inputMode="numeric"
          value={carerSundayHourlyPrice}
          onIonInput={(e) => setCarerSundayHourlyPrice(e.target.value as number)}
          min={request.hourlyPrices.sundayHourlyPrice / 100}
          step={step}
          label={t('sundays')}
          labelPlacement="floating"
          fill="outline"
          required
          helperText={`${t('currentRate')} : ${request.hourlyPrices.sundayHourlyPrice / 100} ${t(
            'currencyPerHour',
          )}`}
        >
          <IonText slot="end">{t('currencyPerHour')}</IonText>
        </IonInput>
      )}
      {!!request.hourlyPrices?.bankHolidayHourlyPrice && (
        <IonInput
          type="number"
          inputMode="numeric"
          value={carerBankHolidayHourlyPrice}
          onIonInput={(e) => setCarerBankHolidayHourlyPrice(e.target.value as number)}
          min={request.hourlyPrices.bankHolidayHourlyPrice / 100}
          step={step}
          label={t('bankHolidays')}
          labelPlacement="floating"
          fill="outline"
          required
          helperText={`${t('currentRate')} : ${
            request.hourlyPrices.bankHolidayHourlyPrice / 100
          } ${t('currencyPerHour')}`}
        >
          <IonText slot="end">{t('currencyPerHour')}</IonText>
        </IonInput>
      )}
      <IonTextarea
        label={t('negotiateRemuneration.explanation')}
        labelPlacement="floating"
        value={explanation}
        onIonInput={(e) => validate(e)}
        onIonBlur={() => markTouched()}
        placeholder={t('negotiateRemuneration.explanationPlaceholder')}
        className={`${isExplanationValid ? 'ion-valid' : 'ion-invalid'} ${
          isExplanationTouched ? 'ion-touched' : ''
        }`}
        errorText={t('negotiateRemuneration.helperText.error')}
        helperText={t('negotiateRemuneration.helperText.hint')}
        fill="outline"
        rows={6}
        required
      />
      <IonCheckbox
        labelPlacement="end"
        alignment="center"
        class="ion-text-wrap"
        className={`${styles.conditionCheckbox}`}
        onIonChange={(e) => setCheckboxCheck(e.detail.checked)}
      >
        <span className="ion-text-wrap">{t('negotiateRemuneration.conditions')}</span>
      </IonCheckbox>
    </form>
  );
};

export default RequestNegotiationForm;
