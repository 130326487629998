import { IonLabel } from '@ionic/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import CardDetail from '@carers/components/CardDetail/CardDetail';
import useCalculatePaymentRemuneration from '@carers/components/PaymentCard/useCalculatePaymentRemuneration';
import { Mission } from '@carers/views/Missions/models/Missions';
import { Request } from '@carers/views/Requests/models/Requests';
import PaymentsIcon from '@shared/assets/icons/payments.svg';
import Alert from '@shared/components/Alert/Alert';
import { CONTRACTS } from '@shared/constants/missionsConstants';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';

type PaymentCardProps = {
  mission: Request | Mission;
  selectedDateSchedulesIds?: string[];
};

const PaymentCard = ({ mission, selectedDateSchedulesIds }: PaymentCardProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const isScreenXL = useIsScreenXL();

  const { cumulatedGrossRemuneration, cumulatedNetRemuneration, cumulatedRemuneration } =
    useCalculatePaymentRemuneration(mission, selectedDateSchedulesIds);

  const isShortTermMission = mission.contract.value === CONTRACTS.SHORT_TERM;

  if (isShortTermMission) {
    return (
      <div className="stack">
        <Alert type="warning" title={t('alerts.temporaryRequest.title')}>
          <IonLabel>{t('alerts.temporaryRequest.message')}</IonLabel>
        </Alert>

        <CardDetail
          icon={isScreenXL ? PaymentsIcon : undefined}
          titleLight={
            <Trans
              i18nKey={t('toKnowYourRemuneration')}
              components={{
                tel: <a href={`tel:${mission.lead.phone}`}>{t('actions.contactUs')}</a>,
              }}
            />
          }
        />
      </div>
    );
  }

  if (selectedDateSchedulesIds) {
    return (
      <CardDetail
        icon={isScreenXL ? PaymentsIcon : undefined}
        title={
          cumulatedGrossRemuneration !== null
            ? cumulatedRemuneration.gross
            : t('remunerationCurrency')
        }
        titleLight={cumulatedNetRemuneration !== null ? cumulatedRemuneration.net : ''}
        text={
          cumulatedGrossRemuneration !== null
            ? t('remunerationSelectedDate')
            : t('noDateSelectedText')
        }
      />
    );
  }

  return (
    <CardDetail
      icon={PaymentsIcon}
      title={cumulatedRemuneration.gross}
      titleLight={cumulatedRemuneration.net}
      text={t('remunerationPurposedDate')}
    />
  );
};

export default PaymentCard;
