import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonLabel,
  IonLoading,
} from '@ionic/react';
import { Dispatch, FormEvent, SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MissionScheduleDates from '@carers/components/MissionScheduleDates/MissionScheduleDates';
import { ModalTitle } from '@carers/components/Modal';
import ScrollableContentButton from '@carers/components/ScrollableContentButton/ScrollableContentButton';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import useCarerHourlyPrices from '@carers/views/Requests/hooks/useCarerHourlyPrices';
import useIsIonContentOverflow from '@carers/views/Requests/hooks/useIsIonContentOverflow';
import useNegotiateRemuneration from '@carers/views/Requests/hooks/useNegotiationRemuneration';
import { Request } from '@carers/views/Requests/models/Requests';
import { ActionResult } from '@carers/views/Requests/RequestDetail/RequestNegotiation/ActionResult/ActionResult';
import RequestNegotiationFooter from '@carers/views/Requests/RequestDetail/RequestNegotiation/RequestNegotiationFooter/RequestNegotiationFooter';
import RequestNegotiationForm from '@carers/views/Requests/RequestDetail/RequestNegotiation/RequestNegotiationForm/RequestNegotiationForm';
import TimeIcon from '@shared/assets/icons/time.svg';
import Alert from '@shared/components/Alert/Alert';
import { logEvent } from '@shared/utils/Analytics';

import styles from './RequestNegotiation.module.css';

type RequestsAcceptanceProps = {
  request: Request;
  dismissAcceptanceModal: () => Promise<unknown>;
  setIsRemunerationNegotiationModalOpen: Dispatch<SetStateAction<boolean>>;
};

const RequestNegotiation = ({
  request,
  dismissAcceptanceModal,
  setIsRemunerationNegotiationModalOpen,
}: RequestsAcceptanceProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const [isNegotiationConditionChecked, setIsNegotiationConditionChecked] = useState(false);

  const carerHourlyPrices = useCarerHourlyPrices(request);

  const {
    carerBusinessDayHourlyPrice,
    carerNightHourlyPrice,
    carerSundayHourlyPrice,
    carerBankHolidayHourlyPrice,
  } = carerHourlyPrices;

  const [explanation, setExplanation] = useState('');
  const [isExplanationValid, setIsExplanationValid] = useState<boolean>();

  const isIonContentOverflow = useIsIonContentOverflow(ionContentRef);

  const {
    mutate: negotiateRemuneration,
    isPending,
    error,
    isError,
    isSuccess,
  } = useNegotiateRemuneration();

  const isSettled = isError || isSuccess;

  const isFormValid = isNegotiationConditionChecked && isExplanationValid;

  const handleSubmitNegotiation = async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!isExplanationValid) {
      return;
    }

    void logEvent(ANALYTICS_LOG_EVENTS.NEGOTIATE_REMUNERATION_CLICK);
    const hourlyPrices = {
      businessDayHourlyPrice: carerBusinessDayHourlyPrice
        ? carerBusinessDayHourlyPrice * 100
        : null,
      nightHourlyPrice: carerNightHourlyPrice ? carerNightHourlyPrice * 100 : null,
      sundayHourlyPrice: carerSundayHourlyPrice ? carerSundayHourlyPrice * 100 : null,
      bankHolidayHourlyPrice: carerBankHolidayHourlyPrice
        ? carerBankHolidayHourlyPrice * 100
        : null,
      explanation,
    };
    negotiateRemuneration({ requestId: request.id, hourlyPrices });
  };

  return (
    <>
      <IonLoading isOpen={isPending} message={t('validation')} />

      {isSettled ? (
        <ActionResult
          handleSubmitAcceptance={handleSubmitNegotiation}
          dismissAcceptanceModal={dismissAcceptanceModal}
          isError={isError}
          error={error ?? undefined}
        />
      ) : (
        <>
          <IonContent ref={ionContentRef} className="ion-padding">
            <ModalTitle id="remuneration-negotiation-modal-title">
              {t('negotiateRemuneration.remunerationNegotiation')}
            </ModalTitle>
            <h6>{request.facility.name}</h6>

            <Alert type="warning" title={t('negotiateRemuneration.alerts.title')}>
              <IonLabel>{t('negotiateRemuneration.alerts.message')}</IonLabel>
            </Alert>
            <IonCard>
              <IonCardHeader className="stack stack-horizontal">
                <IonIcon aria-hidden="true" icon={TimeIcon} className={`${styles.ionIcon}`} />
                <h5>{t('schedule')}</h5>
              </IonCardHeader>
              <IonCardContent>
                <MissionScheduleDates daySchedules={request.daySchedules} />
              </IonCardContent>
            </IonCard>

            <RequestNegotiationForm
              request={request}
              step="0.01"
              setCheckboxChecked={setIsNegotiationConditionChecked}
              handleSubmit={handleSubmitNegotiation}
              carerHourlyPrices={carerHourlyPrices}
              explanation={explanation}
              setExplanation={setExplanation}
              isExplanationValid={isExplanationValid}
              setIsExplanationValid={setIsExplanationValid}
            />

            {isIonContentOverflow && (
              <ScrollableContentButton ionContentRef={ionContentRef.current} />
            )}
          </IonContent>

          <RequestNegotiationFooter
            onClick={setIsRemunerationNegotiationModalOpen}
            isFormValid={isFormValid}
          />
        </>
      )}
    </>
  );
};

export default RequestNegotiation;
