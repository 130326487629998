import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import requestQueries from '@carers/views/Requests/utils/requestQueryFactory';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { httpService } from '@shared/httpService';

type NegotiateRequestRemunerationDTO = {
  requestId: string;
  hourlyPrices: {
    explanation: string;
    businessDayHourlyPrice: number | null;
    nightHourlyPrice: number | null;
    sundayHourlyPrice: number | null;
    bankHolidayHourlyPrice: number | null;
  };
};

const postNegotiateRemuneration = async ({
  requestId,
  hourlyPrices,
}: NegotiateRequestRemunerationDTO) => {
  const url = `carers/requests/${requestId}/negotiate-remuneration`;

  return httpService.post(url, hourlyPrices);
};

const useNegotiateRemuneration = () => {
  const queryClient = useQueryClient();
  const isScreenXL = useIsScreenXL();

  return useMutation({
    mutationFn: postNegotiateRemuneration,
    onSuccess() {
      queryClient.removeQueries({ queryKey: requestQueries.all() });
    },
    onSettled(data, error, variables) {
      if (error?.code === AxiosError.ERR_NETWORK) {
        return null;
      }

      return Promise.all([
        ...(isScreenXL
          ? []
          : [
              queryClient.invalidateQueries({
                queryKey: requestQueries.detail(variables.requestId).queryKey,
              }),
            ]),
        queryClient.invalidateQueries({ queryKey: requestQueries.lists(), refetchType: 'all' }),
      ]);
    },
  });
};

export default useNegotiateRemuneration;
