import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonLoading,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Divider } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import AppInformation from '@carers/components/AppInformation/AppInformation';
import HeaderIcon from '@carers/components/HeaderIcon/HeaderIcon';
import MobileAppDownloadAlert from '@carers/components/MobileAppDownload/MobileAppDownloadAlert';
import { Modal, ModalTitle } from '@carers/components/Modal';
import useGetUserAccount from '@carers/hooks/useGetUserAccount';
import { UserAccount } from '@carers/services/FetchUserAccount';
import { disableFrontChatbot, enableFrontChatbot } from '@carers/utils/FrontChatbot';
import ProfessionalInformationForm from '@carers/views/Profile/components/ProfessionalInformationsForm/ProfessionalInformationsForm';
import ProfileSkeleton from '@carers/views/Profile/components/ProfileSkeleton/ProfileSkeleton';
import UnsubscribeModal from '@carers/views/Profile/components/UnsubscribeModal/UnsubscribeModal';
import useSignOut from '@carers/views/Profile/hooks/useSignOut';
import LogoutIcon from '@shared/assets/icons/logout.svg';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import Refresher from '@shared/components/Refresher/Refresher';
import { PLATFORM } from '@shared/constants/appConstants';
import {
  CGU_LINK,
  FAQ_LINK,
  FEEDBACK_LINK,
  SPONSORSHIP_LINK,
} from '@shared/constants/urlConstants';
import { showConsentModalUI } from '@shared/utils/ConsentManagement';

import profileAvatar from './assets/ProfileAvatar.svg';
import styles from './Profile.module.css';

type UserAccountInformationProps = {
  userAccount: UserAccount;
};

const UserAccountInformation = ({ userAccount }: UserAccountInformationProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });

  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === PLATFORM.WEB;

  const doesAcceptIndependentJobs = !userAccount.doesNotAcceptIndependentJobs;

  return (
    <>
      <IonCard className={`${styles.profileCard}`}>
        <IonCardHeader>
          <div className={`${styles.profileImage}`}>
            <img src={profileAvatar} alt="" />
          </div>
        </IonCardHeader>
        <IonCardContent className="stack">
          <h5>{`${userAccount.firstName} ${userAccount.lastName}`}</h5>
          <h6>{`${userAccount.acceptedJobs[0].label}`}</h6>
          {doesAcceptIndependentJobs && (
            <p>{`${t('SIRETnumber', { siret: userAccount.siretId })}`}</p>
          )}
        </IonCardContent>
      </IonCard>

      {doesAcceptIndependentJobs && !userAccount.siretId && <ProfessionalInformationForm />}

      <IonCard>
        <IonCardContent className={styles.availabilities}>
          <p className="title">{t('availabilities.title')}</p>
          <p>{t('availabilities.message')}</p>
          <IonButton
            expand="block"
            href={userAccount.punctualAvailabilitiesFormUrl}
            target="_blank"
          >
            {t('actions.availabilities')}
          </IonButton>
        </IonCardContent>
      </IonCard>
      {/* TODO @adelebrgx change shouldShowNotificationsMessage to true when B-169 is pushed to prod.
       */}
      {isWebPlatform && <MobileAppDownloadAlert shouldShowNotificationsMessage={false} />}
      <IonCard>
        <IonCardContent>
          <IonButton fill="clear" href={FEEDBACK_LINK} target="_blank">
            {t('actions.carerFeedback')}
          </IonButton>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonCardContent>
          <IonButton fill="clear" href={SPONSORSHIP_LINK} target="_blank">
            {t('actions.sponsorship')}
          </IonButton>
          <Divider className={styles.profileDivider} />
          <IonButton fill="clear" href={FAQ_LINK} target="_blank">
            {t('actions.faq')}
          </IonButton>
          <Divider className={styles.profileDivider} />
          <IonButton
            fill="clear"
            onClick={() => {
              showConsentModalUI();
            }}
          >
            {t('actions.manageConsents')}
          </IonButton>
          <Divider className={styles.profileDivider} />
          <IonButton fill="clear" href={CGU_LINK} target="_blank">
            {t('actions.generalConditions')}
          </IonButton>
          <Divider className={styles.profileDivider} />
          <IonButton
            fill="clear"
            id="open-unsubscribe-modal"
            className={userAccount.hasPendingUnsubscription ? 'ion-hide' : ''}
          >
            {t('actions.unsubscribe')}
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

const Profile = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.profile' });

  const location = useLocation();
  const confirmLogoutModal = useRef<HTMLIonModalElement>(null);
  const unsubscribeModal = useRef<HTMLIonModalElement>(null);
  const { data: userAccount, isLoading, isError, error, refetch } = useGetUserAccount();
  const { mutate: signOut, isPending: isSignOutPending } = useSignOut();

  const enablingFrontChatbot = useCallback(async () => {
    if (userAccount) {
      await enableFrontChatbot(
        userAccount.email,
        userAccount.fullName,
        userAccount.frontAppChatbotUserHash,
      );
    }
  }, [userAccount]);

  useEffect(() => {
    if (/profile$/.test(location.pathname)) {
      enablingFrontChatbot().catch(console.error);
    }

    return disableFrontChatbot;
  }, [location, userAccount]);

  const dismissConfirmLogoutModal = async () => {
    await confirmLogoutModal.current?.dismiss();
  };

  const dismissUnsubscribeModal = async () => {
    await unsubscribeModal.current?.dismiss();
  };

  const logout = async () => {
    await dismissConfirmLogoutModal();
    signOut();
  };

  const logoutUnsubscribe = async () => {
    await dismissUnsubscribeModal();
    signOut();
  };

  const getLogoutModal = () => {
    return (
      <Modal
        ref={confirmLogoutModal}
        className="auto-height"
        trigger="open-confirm-logout-modal"
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        aria-labelledby="logout-modal-title"
      >
        <div className="ion-padding-horizontal ion-padding-bottom">
          <div className={`${styles.safeArea} stack`}>
            <ModalTitle id="logout-modal-title">{t('messages.confirm.logout')}</ModalTitle>
            <IonButton fill="outline" expand="block" onClick={dismissConfirmLogoutModal}>
              {t('actions.cancel')}
            </IonButton>
            <IonButton expand="block" onClick={logout}>
              {t('actions.logout')}
            </IonButton>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <HeaderIcon slot="start" />
          <IonTitle>{t('pageTitle')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={`ion-padding ${styles.ionContent} ${!isError && 'withBlob'}`}>
        <Refresher refetch={refetch} />
        {isError ? (
          <BlankSlateError error={error} action={refetch}>
            <IonButton fill="clear" id="open-confirm-logout-modal">
              <IonIcon slot="start" aria-hidden="true" icon={LogoutIcon} />
              {t('actions.logout')}
            </IonButton>
            {getLogoutModal()}
          </BlankSlateError>
        ) : (
          <>
            <IonLoading message={t('messages.info.logoutInProgress')} isOpen={isSignOutPending} />

            <div className={`${styles.pageContent} stack`}>
              {isLoading && <ProfileSkeleton />}

              {userAccount && (
                <>
                  <UserAccountInformation userAccount={userAccount} />

                  <IonButton fill="clear" id="open-confirm-logout-modal">
                    <IonIcon slot="start" aria-hidden="true" icon={LogoutIcon} />
                    {t('actions.logout')}
                  </IonButton>

                  <AppInformation />

                  <UnsubscribeModal
                    refUnsubscribeModal={unsubscribeModal}
                    cancel={dismissUnsubscribeModal}
                    logout={logoutUnsubscribe}
                  />

                  {getLogoutModal()}
                </>
              )}
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Profile;
