import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import { OverlayEventDetail } from '@ionic/react/dist/types/components/react-component-lib/interfaces';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import AdditionalInformationCard from '@carers/components/AdditionalInformationCard/AdditionalInformationCard';
import CardDetail from '@carers/components/CardDetail/CardDetail';
import MissionScheduleDates from '@carers/components/MissionScheduleDates/MissionScheduleDates';
import { isModalClosedIntentionally, Modal } from '@carers/components/Modal';
import PageContentWithSidePanel from '@carers/components/PageContentWithSidePanel/PageContentWithSidePanel';
import PaymentCard from '@carers/components/PaymentCard/PaymentCard';
import RoundWaveContentHeader from '@carers/components/RoundWaveContentHeader/RoundWaveContentHeader';
import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import useGetRequest from '@carers/views/Requests/hooks/useGetRequest';
import RequestAcceptance from '@carers/views/Requests/RequestDetail/RequestAcceptance/RequestAcceptance';
import RequestDetailSkeleton from '@carers/views/Requests/RequestDetail/RequestDetailSkeleton/RequestDetailSkeleton';
import RequestNegotiation from '@carers/views/Requests/RequestDetail/RequestNegotiation/RequestNegotiation';
import { isRequestUnavailable } from '@carers/views/Requests/utils/requestErrors';
import BadgeIcon2 from '@shared/assets/icons/badge-2.svg';
import BusinessIcon from '@shared/assets/icons/business.svg';
import PhoneIcon from '@shared/assets/icons/local_phone.svg';
import LocationIcon from '@shared/assets/icons/location.svg';
import TimeIcon from '@shared/assets/icons/time.svg';
import Alert from '@shared/components/Alert/Alert';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import Refresher from '@shared/components/Refresher/Refresher';
import { SERVICES } from '@shared/constants/missionsConstants';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { HTTPErrorCode } from '@shared/HttpInterceptors/HTTP_ERROR_CODES';
import { logEvent } from '@shared/utils/Analytics';

import styles from './RequestDetail.module.css';

const RequestDetail = () => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const isScreenXL = useIsScreenXL();
  const { id: requestId } = useParams<{ id: string }>();
  const router = useIonRouter();
  const [isRemunerationNegotiationModalOpen, setIsRemunerationNegotiationModalOpen] =
    useState(false);

  const { data: request, refetch, error, isError, isLoading, isSuccess } = useGetRequest(requestId);

  const shouldShowAdditionalInformationCard = !!request?.unit || !!request?.additionalInformation;

  const acceptanceModal = useRef<HTMLIonModalElement>(null);

  const dismissAcceptanceModal = async () => {
    await acceptanceModal.current?.dismiss();
  };

  const goToRequests = async () => {
    router.push('/carers/requests', 'back');
  };

  const renderModalAndSideContent = () => {
    if (!request) {
      return null;
    }

    return !isRemunerationNegotiationModalOpen ? (
      <RequestAcceptance
        dismissAcceptanceModal={dismissAcceptanceModal}
        request={request}
        setIsRemunerationNegotiationModalOpen={setIsRemunerationNegotiationModalOpen}
      />
    ) : (
      <RequestNegotiation
        dismissAcceptanceModal={dismissAcceptanceModal}
        request={request}
        setIsRemunerationNegotiationModalOpen={setIsRemunerationNegotiationModalOpen}
      />
    );
  };

  const onWillAcceptanceModalDismiss = (event: CustomEvent<OverlayEventDetail<void>>) => {
    const { role } = event.detail;

    setIsRemunerationNegotiationModalOpen(false);

    if (
      isModalClosedIntentionally(role) &&
      isError &&
      isRequestUnavailable(error.response?.data.error?.code)
    ) {
      router.push('/carers/requests', 'back');
    }
  };

  const isAtHomeMission = request?.service.value === SERVICES.HOME_NURSING_SERVICES;

  const getErrorContent = (err: GenericAxiosResponseError) => {
    const wasRequestNotFulfilled =
      err.response?.data.error?.code === HTTPErrorCode.REQUEST_END_DATE_SHOULD_BE_ON_OR_AFTER_TODAY;

    const action = wasRequestNotFulfilled ? goToRequests : refetch;
    const actionName = wasRequestNotFulfilled ? t('actions.showRequests') : t('actions.retry');

    return (
      <IonContent>
        <Refresher refetch={refetch} />
        <BlankSlateError error={err} action={action} actionName={actionName} />
      </IonContent>
    );
  };

  const getPaymentCard = () => {
    if (!request) {
      return null;
    }

    return (
      <div className="stack">
        {isAtHomeMission && (
          <Alert type="warning" title={t('alerts.atHomeMission.title')}>
            <IonLabel>{t('alerts.atHomeMission.message')}</IonLabel>
          </Alert>
        )}
        <PaymentCard mission={request} />
      </div>
    );
  };

  /* eslint complexity: "off" */
  const getContentRequestDetail = () => {
    if (isError) {
      if (isRequestUnavailable(error.response?.data.error?.code)) {
        void logEvent(ANALYTICS_LOG_EVENTS.UNAVAILABLE_REQUEST_DETAIL);
      }

      return getErrorContent(error);
    }

    if (isLoading) {
      return <RequestDetailSkeleton />;
    }

    if (!isSuccess) {
      return null;
    }

    return (
      <>
        <PageContentWithSidePanel sidePanel={renderModalAndSideContent()}>
          <IonContent className="ion-padding withBlob">
            <Refresher refetch={refetch} />
            <RoundWaveContentHeader />
            <div className="stack">
              <IonCard>
                <IonCardHeader className={styles.ionCardHeader}>
                  <IonChip color="primary" disabled>
                    {request.contract.label}
                  </IonChip>
                  <h5>{request.facility.name}</h5>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>{`${request.facility.address}, ${request.facility.postalCode} ${request.facility.city}`}</IonText>
                  <div className="stack">
                    <IonButton
                      fill="clear"
                      className="button-link"
                      style={{ margin: 'auto' }}
                      href={request.facility.googleMapsUrl}
                      target="blank"
                    >
                      <IonIcon aria-hidden="true" slot="start" icon={LocationIcon} />
                      {t('actions.locateFacility')}
                    </IonButton>
                  </div>
                </IonCardContent>
              </IonCard>
              <div className={`stack ${isScreenXL && 'stack-horizontal'}`}>
                <div className={`stack ${!isScreenXL && 'stack-horizontal'}`}>
                  <CardDetail icon={BadgeIcon2} title={request.job.label} />
                  <CardDetail icon={BusinessIcon} title={request.service.label} />
                </div>

                {getPaymentCard()}
              </div>
              <IonCard>
                <IonCardHeader className="stack stack-horizontal" style={{ alignItems: 'center' }}>
                  <IonIcon
                    aria-hidden="true"
                    icon={TimeIcon}
                    style={{ fontSize: '24px', opacity: 0.5 }}
                  />
                  <h5>{t('schedule')}</h5>
                </IonCardHeader>
                <IonCardContent>
                  <MissionScheduleDates daySchedules={request.daySchedules} />
                </IonCardContent>
              </IonCard>
              {shouldShowAdditionalInformationCard && (
                <AdditionalInformationCard request={request} />
              )}

              <div className="ion-text-center ion-text-xl-end">
                <IonButton
                  fill="clear"
                  className="ion-text-wrap"
                  href={`tel:${request.lead.phone}`}
                >
                  <IonIcon aria-hidden="true" slot="start" icon={PhoneIcon} />
                  {t('contact')}
                </IonButton>
              </div>
            </div>
          </IonContent>
        </PageContentWithSidePanel>

        {!isScreenXL && (
          <IonFooter>
            <IonToolbar color="light" className={`${styles.safeArea}`}>
              <div slot="primary" className="stack ion-padding">
                <IonButton expand="block" id="open-acceptance-modal">
                  {t('actions.selectDates')}
                </IonButton>
              </div>
            </IonToolbar>
          </IonFooter>
        )}
      </>
    );
  };
  /* eslint complexity: "error" */

  return (
    <IonPage className={styles.multipleContent}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/carers/requests" />
          </IonButtons>
          <IonTitle>{t('title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      {getContentRequestDetail()}

      {!isScreenXL && request && (
        <Modal
          id="open-acceptance-modal"
          ref={acceptanceModal}
          trigger="open-acceptance-modal"
          onWillDismiss={onWillAcceptanceModalDismiss}
          breakpoints={[0, 1]}
          initialBreakpoint={1}
          aria-labelledby="acceptance-modal-title"
        >
          {renderModalAndSideContent()}
        </Modal>
      )}
    </IonPage>
  );
};

export default RequestDetail;
