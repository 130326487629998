const FRONT_CHATBOT_SCRIPT_ID = 'frontChatbotScript';
const FRONT_CHATBOT_SCRIPT_SOURCE = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';

type FrontChat = (cmdType: string, params?: Record<string, string | boolean | object>) => void;

declare global {
  interface Window {
    FrontChat: FrontChat;
  }
}

const getFrontChatbotScript = () => {
  const script = document.createElement('script');
  script.id = FRONT_CHATBOT_SCRIPT_ID;
  script.src = FRONT_CHATBOT_SCRIPT_SOURCE;
  script.async = true;

  script.onload = () => {
    if (!window.FrontChat) {
      throw new Error('[FrontChatbot] Could not set up window.FrontChat');
    }
  };

  return script;
};

let isChatbotInitialized: boolean = false;

const initFrontChatbot = () => {
  if (!isChatbotInitialized) {
    const script = getFrontChatbotScript();
    document.head.appendChild(script);
    isChatbotInitialized = true;
  }

  const cleanupCallback = () => {
    if (isChatbotInitialized) {
      const script = document.getElementById(FRONT_CHATBOT_SCRIPT_ID);

      if (!!script) {
        document.head.removeChild(script);
      }

      isChatbotInitialized = false;
    }
  };

  return cleanupCallback;
};

let isChatbotEnabled: boolean = false;

const enableFrontChatbot = async (
  email: string,
  name: string,
  userHash: string,
): Promise<FrontChat | null> => {
  if (isChatbotEnabled) {
    return null;
  }

  if (!window.FrontChat) {
    throw new Error('[FrontChatbot] enable failed: window.FrontChat is undefined');
  }

  const promise = new Promise<FrontChat>((resolve) => {
    window.FrontChat('init', {
      chatId: import.meta.env.VITE_FRONT_CHATBOT_ID,
      email,
      name,
      userHash,
      onInitCompleted: () => {
        isChatbotEnabled = true;
        resolve(window.FrontChat);
      },
    });
  });

  return promise;
};

const disableFrontChatbot = () => {
  if (!isChatbotEnabled) {
    return;
  }

  if (!window.FrontChat) {
    throw new Error('[FrontChatbot] disable failed: window.FrontChat is undefined');
  }

  window.FrontChat('shutdown');
  isChatbotEnabled = false;
};

export { initFrontChatbot, enableFrontChatbot, disableFrontChatbot };
