import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';

import useGetAppConstants from '@carers/hooks/useGetAppConstants';
import CarersRouteId from '@carers/routes/route';
import { CARERS_MAIN_ROUTES, CARERS_TABS_ROOT_ROUTES } from '@carers/routes/routes';
import { initFrontChatbot } from '@carers/utils/FrontChatbot';
import Activity from '@carers/views/Activity/Activity';
import AuthHeader from '@carers/views/Auth/components/AuthHeader';
import MissionDetail from '@carers/views/Missions/MissionDetail/MissionDetail';
import Missions from '@carers/views/Missions/MissionsList/MissionsList';
import Profile from '@carers/views/Profile/Profile';
import RequestDetail from '@carers/views/Requests/RequestDetail/RequestDetail';
import Requests from '@carers/views/Requests/RequestsList/RequestsList';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import BlankSlateError from '@shared/components/BlankSlate/BlankSlateError';
import useDisplayTabBar from '@shared/hooks/useDisplayTabBar';
import useIsScreenXL from '@shared/hooks/useIsScreenXL';
import styles from '@shared/views/tabs/TabsOutlet.module.css';
import XLScreenSideHeader from '@shared/views/XLScreenSideHeader/XLScreenSideHeader';

const mainRoutesComponents: Record<CarersRouteId, React.FC> = {
  activity: Activity,
  requestsList: Requests,
  missionsList: Missions,
  profile: Profile,
  missionDetail: MissionDetail,
  requestDetail: RequestDetail,
};

const TabsOutlet = () => {
  const { t } = useTranslation();
  const isScreenXL = useIsScreenXL();
  const isTabBarVisible = useDisplayTabBar(isScreenXL);
  const { isError, error, isLoading, refetch } = useGetAppConstants();

  useEffect(() => {
    const cleanupFrontChatbot = initFrontChatbot();

    const cleanup = () => {
      cleanupFrontChatbot();
    };

    return cleanup;
  }, []);

  if (isLoading || isError) {
    return (
      <IonPage>
        <AuthHeader />
        <IonContent>
          {isLoading && <BlankSlate message={t('shared.messages.info.loading')} />}
          {isError && <BlankSlateError error={error} action={refetch} />}
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {isScreenXL && <XLScreenSideHeader />}
      <IonTabs className={styles.ionTabs}>
        <IonRouterOutlet animated={!isScreenXL}>
          {CARERS_MAIN_ROUTES.map((tab) => (
            <Route exact path={tab.path} component={mainRoutesComponents[tab.id]} key={tab.id} />
          ))}
        </IonRouterOutlet>
        <IonTabBar
          id="main-tab-bar"
          slot="bottom"
          className={`${styles.ionTabBar} ${isTabBarVisible ? '' : 'ion-hide'}`}
        >
          {CARERS_TABS_ROOT_ROUTES.map((tab) => (
            <IonTabButton key={tab.id} tab={tab.id} href={tab.path} className={styles.ionTabButton}>
              <IonIcon aria-hidden="true" src={tab.icon} className={styles.ionIcon} />
              <IonLabel aria-label={t(tab.label)} role="navigation">
                {t(tab.label)}
              </IonLabel>
            </IonTabButton>
          ))}
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export default TabsOutlet;
