/* eslint-disable no-console */
import { App } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { isPlatform } from '@ionic/react';
import { useEffect } from 'react';
import { useEffectOnce } from 'usehooks-ts';

import useAuthState from '@carers/hooks/useAuthState';
import useRedirectPathAfterSignIn from '@carers/hooks/useRedirectPathAfterSignIn';
import { PLATFORM } from '@shared/constants/appConstants';

interface BatchPushEvent extends Event {
  payload: {
    url?: string;
  };
}

const AppUrlOpenListener = () => {
  const { isAuth } = useAuthState();
  const { setRedirectPathAfterSignIn } = useRedirectPathAfterSignIn();

  const redirect = (rawUrl: string) => {
    const url = new URL(rawUrl);

    if (url.pathname) {
      if (!isAuth) {
        setRedirectPathAfterSignIn(url.pathname + url.search);
      }

      window.location.replace(url.pathname + url.search);
    }
  };

  const handleBatchPush = (e: Event) => {
    const platform = Capacitor.getPlatform();
    const isIOSPlatform = platform === PLATFORM.IOS;

    // Note(@ndbn): due to conflict issue with the Batch cordova plugin
    // native handling of deep links in push notification is broken for IOS
    // so we handle them on the JS side
    if (!isIOSPlatform) {
      return;
    }

    const customEvent = e as BatchPushEvent;

    const { url } = customEvent.payload;

    if (url) {
      redirect(url);
    }
  };

  const initAppUrlOpenListener = async (signal: AbortSignal) => {
    let listener: PluginListenerHandle;

    const removeListener = () => {
      listener.remove().catch(() => {});
    };

    listener = await App.addListener('appUrlOpen', (event) => {
      redirect(event.url);
    });

    if (signal.aborted) {
      removeListener();
    } else {
      signal.addEventListener('abort', removeListener, { once: true });
    }
  };

  useEffectOnce(() => {
    const controller = new AbortController();

    if (isPlatform('capacitor')) {
      initAppUrlOpenListener(controller.signal).catch(() => {});
    }

    return () => controller.abort();
  });

  useEffect(() => {
    document.addEventListener('batchPushReceived', handleBatchPush, false);

    return () => {
      document.removeEventListener('batchPushReceived', handleBatchPush);
    };
  }, []);

  return null;
};

export default AppUrlOpenListener;
