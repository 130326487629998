import { IonButton, IonContent, IonFooter, IonLabel, IonToolbar, useIonRouter } from '@ionic/react';
import { AxiosError } from 'axios';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ANALYTICS_LOG_EVENTS } from '@carers/utils/Analytics.ts';
import {
  isRequestCancelled,
  isRequestUnavailable,
} from '@carers/views/Requests/utils/requestErrors';
import ErrorImage from '@shared/assets/images/status/generic-error.svg';
import SuccessImage from '@shared/assets/images/status/generic-success.svg';
import NetworkErrorImage from '@shared/assets/images/status/network-error.svg';
import Alert, { AlertProps } from '@shared/components/Alert/Alert';
import BlankSlate from '@shared/components/BlankSlate/BlankSlate';
import { GenericAxiosResponseError } from '@shared/HttpInterceptors/GenericAxiosResponseError';
import { logEvent } from '@shared/utils/Analytics';

type ActionResultType = {
  message?: string;
  action?: () => void;
  actionName?: string;
  image?: string;
  alertTitle?: string;
  alertMessage?: string;
  typeAlert: AlertProps['type'];
};

type ActionResultProps = {
  handleSubmitAcceptance: (event?: FormEvent<HTMLFormElement>) => Promise<void>;
  dismissAcceptanceModal: () => Promise<unknown>;
  isError: boolean;
  error?: GenericAxiosResponseError;
};

const ActionResult = ({
  handleSubmitAcceptance,
  dismissAcceptanceModal,
  isError = false,
  error,
}: ActionResultProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'carers.requests.detail' });
  const router = useIonRouter();

  const goToRequests = async () => {
    await dismissAcceptanceModal();
    router.push('/carers/requests', 'back');
  };

  const getActionResultConfigOnError = ({
    code,
    response,
    errorMessage,
  }: GenericAxiosResponseError): ActionResultType => {
    if (response?.data.error?.code) {
      const actionResult: Partial<ActionResultType> = {
        message: errorMessage,
        image: ErrorImage,
        actionName: t('actions.showRequests'),
        action: goToRequests,
      };

      if (isRequestUnavailable(response.data.error.code)) {
        void logEvent(ANALYTICS_LOG_EVENTS.ACCEPT_UNAVAILABLE_REQUEST);

        return {
          ...actionResult,
          alertTitle: t('alerts.missionNotAvailableTitle'),
          alertMessage: t('alerts.missionNotAvailableText'),
          typeAlert: 'info',
        };
      }

      if (isRequestCancelled(response.data.error.code)) {
        return {
          ...actionResult,
          alertTitle: t('alerts.cancelledRequestError.title'),
          alertMessage: t('alerts.cancelledRequestError.message'),
          typeAlert: 'info',
        };
      }
    }

    return {
      message: errorMessage,
      image: code === AxiosError.ERR_NETWORK ? NetworkErrorImage : ErrorImage,
      actionName: t('actions.retry'),
      typeAlert: 'info',
      action: handleSubmitAcceptance,
    };
  };

  const configActionResult: ActionResultType =
    isError && error
      ? getActionResultConfigOnError(error)
      : {
          message: t('negotiateRemuneration.successMessage'),
          image: SuccessImage,
          alertTitle: t('negotiateRemuneration.alerts.title'),
          alertMessage: t('negotiateRemuneration.alerts.message'),
          actionName: t('actions.showRequests'),
          typeAlert: 'warning',
          action: goToRequests,
        };

  return (
    <>
      <IonContent>
        <BlankSlate image={configActionResult.image}>
          <h5 color="primary" className="ion-no-margin">
            {configActionResult.message}
          </h5>
          {(configActionResult.alertTitle || configActionResult.alertMessage) && (
            <Alert type={configActionResult.typeAlert} title={configActionResult.alertTitle}>
              <IonLabel>{configActionResult.alertMessage}</IonLabel>
            </Alert>
          )}
        </BlankSlate>
      </IonContent>
      <IonFooter>
        <IonToolbar color="light">
          <div slot="primary" className="stack ion-padding">
            <IonButton fill="outline" expand="block" onClick={configActionResult.action}>
              {configActionResult.actionName}
            </IonButton>
          </div>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export { ActionResult };
export type { ActionResultProps };
